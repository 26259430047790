
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import IconBadge from '@/components/design-system/icons/IconBadge.vue'
import StackComponent from '@/components/design-system/layout/StackComponent.vue';

import {
  vkiLogoInFavour,
  vkiLogoAgainst,
} from '@/components/design-system/icons';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Nevyhovující příklad</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Vyhovující příklad</p>
</stack-component>
<h2>1. Otázka se musí týkat toho, co mají zvolení politici šanci ovlivnit.</h2>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> ČR by měla vystoupit z EU. (otázka v krajských volbách)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> ČR by měla vystoupit z EU. (otázka v celostátních volbách)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Chtěl/a bych, aby se v příštích volbách Praha vrátila k
systému jediného volebního obvodu. (otázka v pražských volbách)</p>
</stack-component>
<h2>2. Na otázku musí jít odpovědět ano i ne a neměla by navádět k odpovědi.</h2>
<p>Při tvorbě si musíme umět představit lidi, kteří vybírají obě varianty odpovědi, ano i ne. Z otázky by také nemělo být poznat, jak na ni odpovídá sám autor otázky.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Měla by se zlepšit dopravní situace v městských částech
Považský Chlmec a Vranie?</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Chtěl/a bych, aby Opencard nebyla pro cestující cenově
výhodnější oproti „papírové tramvajence“, aby si cestující mohli vybrat. (“aby si mohli vybrat” navádí k odpovědi)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Měla by se vybudovat oddychová zóna pri Rajčianke? (pouze za
předpokladu, že “každý” ví, o co jde, diskutovalo se o tom po městě hodně, ví se, kolik by to stálo, apod.)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Mělo by se alespoň 1% z rozpočtu města určeného na dopravu
vyčlenit na cyklistickou dopravu?</p>
</stack-component>
<h2>3. Otázky mají být důležité</h2>
<p>Bereme např. v potaz, na co vydává dané zastupitelstvo/parlament nejvíce veřejných peněz.</p>
<p>Témata vybíráme tak, aby byla relevantní pro všechny skupiny voličů (věkové, příjmové, zájmové).</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Mělo se město omluvit pí. Lorenzové a p. Cejthamrovi za
výroky, které při této konfrontaci byly vysloveny?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali byste za zrušení garance a automatický vstup do
druhého pilíře?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali byste za důvěru vládě a permanentní euroval?</p>
</stack-component>
<h2>4. Otázka má být co nejkonkrétnější</h2>
<p>Jde nám o to, aby se dala odpověď zkontrolovat po 4 letech/na konci volebního období.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Měla by sa zahušťovat výstavba v centru města?</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Podle mého názoru by z letiště Ruzyně do centra Prahy měla
vést rychlodráha. (správně by bylo: Budu prosazovat/chci, aby se rychlodráha z letiště do centra Prahy začala budovat v následujících 4 letech)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Měla by se zvýšit dotace pro MHD minimálně o 10% oproti roku
2010?</p>
</stack-component>
<h2>5. Otázka musí být krátká a srozumitelná</h2>
<p>Chceme, aby tázaný/á otázky opravdu pročetl/a a porozuměl/a jim. Formulujeme je tedy o max. délce 20 slov, případný popis omezujeme na 50 slov.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Hlasovali byste pro:<br>
zrušení sociálního příplatku pro sólo rodiče,<br>
redukci dávky – porodného jen na nízkopříjmové rodiče,<br>
jednotnou celkovou vyplacenou částku rodičovského příspěvku (220 000) a větší flexibilitu ve volbě výše a délky RP,<br>
snížení příspěvku na péči,<br>
snížení podpory v nezaměstnanosti a zrušení možnosti minimálního přivýdělku k podpoře,<br>
zavedení příspěvku pro začínající podnikatele/ky,<br>
zpřísnění podmínek nároku na nemocenské a mateřskou u OSVČ?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Souhlasím se zavedením turniketů do metra.</p>
</stack-component>
<h2>6. Otázky s popisem musejí dávat smysl i bez něj</h2>
<p>Velký počet lidí totiž čte pouze otázku samotnou, ne její popis. Může se take stát, že popis na mobilním zařízení nezobrazí.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Hlasovali by ste za zrušení garancie a automatický vstup do
druhého pilíře?</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Má podle Vás smysl připojovat se k podobným projektům?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Souhlasím se zpoplatněním vjezdu automobilů do centra města,
například formou mýtného.</p>
</stack-component>
<h2>7. Upřednostňujeme otázky v pozitivním tvaru, vyhýbáme se matoucí dvojité negaci.</h2>
<p>Z příkladu níže je viditelné, že dvojitá negace je zavádějící a nepřesná.</p>
<br>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Na Vysočině by úložiště jaderného odpadu nemělo vzniknout za
žádnou cenu.</p>
<br>
<p>Zde totiž není jasné, s čím tázaný/á nesouhlasí:</p>
<p>a) ne, nemělo by vzniknout,</p>
<p>b) nesouhlasím, mělo by vzniknout.</p>
<p>Správně formulována otázka by měla znít: “Měl by kraj učinit vše proti vzniku úložiště jaderného odpadu?”)</p>
<br>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Střední školy by se měly nadále slučovat do větších celků.</p>
<br>
<p>Nikoliv “Střední školy už by se nadále neměly slučovat do větších celků”.</p>
<h2>8. Otázka musí být psána snadno pochopitelným jazykem</h2>
<p>Otázky píšeme tak, aby ji a tématu porozuměli různé společenské skupiny.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Nový územní plán musí regulovat výškovou výstavbu, která
může poškodit panorama Prahy.</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Výstavba mrakodrapů v historickém centru má být zakázána.</p>
</stack-component>
<h2>9. Vybíráme i otázky, které jsou zajímavé, i když ne zcela důležité.</h2>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali byste za (myšleno ironicky) návrh barevně
napovídat poslancům, jak hlasovat?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali byste pro zákon “Václav Havel se zasloužil o
svobodu a demokracii.”?</p>
</stack-component>
</div>
</template>
