<template>
  <div class="root-layout">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.root-layout {
  display: grid;
  min-height: 100%;
}
</style>
