
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import { mdiArrowDown, mdiArrowRight, mdiEmailOutline } from '@mdi/js';

import BackgroundComponent from '@/components/design-system/style/BackgroundComponent.vue';
import BlobComponent from '@/components/design-system/style/BlobComponent.vue';
import BodyText from '../../components/design-system/typography/BodyText.vue';
import ButtonComponent from '../../components/design-system/input/ButtonComponent.vue';
import CardComponent from '@/components/design-system/containers/CardComponent.vue';
import DonateBlock from '@/components/DonateBlock.vue';
import FooterMultiWord from '@/components/FooterMultiWord.vue';
import FormComponent from '@/components/design-system/input/FormComponent.vue';
import HeadlineText from '@/components/design-system/typography/HeadlineText.vue';
import IconComponent from '@/components/design-system/icons/IconComponent.vue';
import InfoBubble from '@/components/InfoBubble.vue';
import MasonryGrid from '@/components/design-system/layout/MasonryGrid.vue';
import NavigationBar from '@/components/design-system/navigation/NavigationBar.vue';
import StackComponent from '../../components/design-system/layout/StackComponent.vue';
import StaticContentLayout from '@/components/layouts/StaticContentLayout.vue';
import StickyHeaderLayout from '@/components/layouts/StickyHeaderLayout.vue';
import TextInputComponent from '@/components/design-system/input/TextInputComponent.vue';
import TitleText from '@/components/design-system/typography/TitleText.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<p>Poslední volby se v Česku konaly před rokem a půl – prezidentské v lednu 2023. A tedy i prezidentská kalkulačka byla až do teď ta poslední.</p>
<p>Ale spustili jsme od té doby spoustu kalkulaček <a href="https://volebnakalkulacka.sk">na Slovensku</a>, <a href="https://wahlrechner.at">v Rakousku</a> a <a href="https://voksmonitor.hu">Maďarsku</a>.</p>
<p>Společně s prezidentskou kalkulačkou jsme chtěli přidat funkci profilu, abyste se mohli k výsledkům svých kalkulaček vracet. Bohužel jsme v tom trochu přecenili síly a tak jsme pro evropské volby museli udělat malý krok zpět a spustit evropskou kalkulačku odděleně, bez profilu.</p>
<p>Možnost <strong>přihlásit se</strong> do svého profilu a <strong>prezidentskou, senátní a komunální</strong> kalkulačku tak teď najdete v <strong><a href="https://archiv.volebnikalkulacka.cz">archivu</a></strong> na adrese <a href="https://archiv.volebnikalkulacka.cz">archiv.volebnikalkulacka.cz</a>.</p>
<p>Máme v plánu kalkulačky zase sloučit a pro krajské a senátní, ale hlavně parlamentní volby příští rok chystáme další vylepšení, aby kalkulačka byla ještě lepší, než kdy dřív. Nechcete nám s tím pomoct? Pokud byste chtěli pomoct jako dobrovolník, ozvěte se nám na <a href="mailto:info@kohovolit.eu">info@kohovolit.eu</a> nebo budeme rádi, když nás <a href="https://www.darujme.cz/darovat/1200653">podpoříte</a> finančně.</p>
<br>
<masonry-grid style="align-self: stretch">
   <card-component corner="top-right" padding="medium" border="" shadow="">
   <div class="card-content">
      <div class="card-content-text">
         <title-text tag="h3" size="medium">Prezidentské volby 2023</title-text>
         <body-text size="medium">Volby prezidenta České republiky 2023, 42 otázek, cca 10
         minut</body-text>
      </div>
      <button-component kind="filled" color="primary" tag="a" href="https://archiv.volebnikalkulacka.cz/volby/prezidentske-2023/pro-kazdeho/navod">
         Spustit kalkulačku pro 1. kolo
         <template #iconafter="">
         <icon-component :icon="mdiArrowRight" />
         </template>
      </button-component>
   </div>
   </card-component>
   <card-component corner="top-right" padding="medium" border="" shadow="">
   <div class="card-content">
      <div class="card-content-text">
         <title-text tag="h3" size="medium">Prezidentské volby 2023: 2. kolo</title-text>
         <body-text size="medium">Volební kalkulačka pro 2. kolo, 20 otázek, cca 5
         minut</body-text>
      </div>
      <button-component kind="outlined" color="primary" tag="a" href="https://archiv.volebnikalkulacka.cz/volby/prezidentske-2023/pro-kazdeho-2-kolo/navod">
         Spustit kalkulačku
         <template #iconafter="">
         <icon-component :icon="mdiArrowRight" />
         </template>
      </button-component>
   </div>
   </card-component>
   <card-component corner="top-right" padding="medium" border="" shadow="">
   <div class="card-content">
      <div class="card-content-text">
         <title-text tag="h3" size="medium">Prezidentské volby 2023: pro nadšence</title-text>
         <body-text size="medium">Všech 98 otázek, které jsme položili prezidentským
         kandidátům.
         </body-text>
      </div>
      <button-component kind="outlined" color="primary" tag="a" href="https://archiv.volebnikalkulacka.cz/volby/prezidentske-2023/pro-nadsence/navod">
         Spustit kalkulačku
         <template #iconafter="">
         <icon-component :icon="mdiArrowRight" />
         </template>
      </button-component>
   </div>
   </card-component>
   <card-component corner="top-right" padding="medium" border="" shadow="">
   <div class="card-content">
      <div class="card-content-text">
         <title-text tag="h3" size="medium">Prezidentské volby 2023: pro mladé</title-text>
         <body-text size="medium">Otázky, které rezonují mladou generací.<br>
         Ve spolupráci s projektem
         <a href="https://www.nazorypolitiku.cz" target="_blank">
            NázoryPolitiků.cz </a>.</body-text>
      </div>
      <button-component kind="outlined" color="primary" tag="a" href="https://archiv.volebnikalkulacka.cz/volby/prezidentske-2023/pro-mlade/navod">
         Spustit kalkulačku
         <template #iconafter="">
         <icon-component :icon="mdiArrowRight" />
         </template>
      </button-component>
   </div>
   </card-component>
   <card-component corner="top-right" padding="medium" border="" shadow="">
   <div class="card-content">
      <div class="card-content-text">
         <title-text tag="h3" size="medium">Komunální volby 2022</title-text>
         <body-text size="medium">K dispozici jsou kalkulačky pro vybraná města.</body-text>
         <div class="divider"></div>
         <body-text size="small">Komunální kalkulačku vyplnilo přes 100 tisíc lidí.</body-text>
      </div>
      <button-component kind="filled" color="primary" href="https://archiv.volebnikalkulacka.cz/volby/komunalni-2022/vyber">Spustit kalkulačku</button-component>
   </div>
   </card-component>
   <card-component corner="top-left" padding="medium" border="" shadow="">
   <div class="card-content">
      <div class="card-content-text">
         <title-text tag="h3" size="medium">Senátní volby 2022</title-text>
         <body-text size="medium">Pro jednotlivé volební obvody.</body-text>
         <body-text size="medium"><a href="https://2022.programydovoleb.cz/senatni-volby#kde-se-letos-voli">Více o senátních obvodech</a></body-text>
      </div>
      <div class="divider"></div>
      <body-text size="small">Senátní kalkulačku vyplnilo přes 25 tisíc lidí.</body-text>
      <button-component color="primary" kind="filled" href="https://archiv.volebnikalkulacka.cz/volby/senatni-2022/vyber">Spustit kalkulačku</button-component>
   </div>
   </card-component>
</masonry-grid></div>
</template>
